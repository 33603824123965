.ui__hero_button {
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.ui__hero_button__bg {
  transition: 0.3s;
}

.ui__hero_button:hover .ui__hero_button__bg {
  opacity: 0;
}

.ui__hero_button--loading,
.ui__hero_button--disabled {
  cursor: initial !important;
}

.ui__hero_button--loading .ui__hero_button__bg,
.ui__hero_button--disabled .ui__hero_button__bg {
  opacity: 0;
}
