html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif;
}

// hack connect wallet
body {
  padding-right: 0 !important;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}

::selection {
  background-color: #ff2800;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

* {
  box-sizing: border-box;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply min-w-[1100px];
  }

  .container.mx-auto {
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
  }

  .btn-unstyled {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
    text-transform: none;
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-width: 0;
    @apply cursor-pointer;
  }

  .a-hover-line::after {
    @apply transition-transform ease-linear duration-200 content-[''] block w-full h-[1px] bg-white origin-right scale-0;
    position: relative;
    top: 1px;
  }

  .a-hover-line:hover::after {
    @apply scale-100 origin-left;
  }

  .a-hover-line2::after {
    @apply transition-transform ease-linear duration-200 content-[''] block w-full h-[1px] bg-[rgba(255,255,255,0.5)] origin-right scale-0;
    position: relative;
    top: 1px;
  }

  .a-hover-line2:hover::after {
    @apply scale-100 origin-left;
  }

  .menu-link-hover-line::after {
    @apply transition-transform ease-linear duration-200 content-[''] block w-full h-[1px] origin-right scale-0;
    position: absolute;
    bottom: -1px;
    background-image: linear-gradient(
      90deg,
      #0096ff 0%,
      #bc2aef 50.83%,
      #ff2800 100%
    );
  }

  .menu-link-hover-line:hover::after {
    @apply scale-100 origin-left;
  }

  html {
    --morebtn-x: 222;
    --morebtn-y: 222;
  }

  .home-more {
    transform: translate3d(
        calc(var(--morebtn-x) * 1px),
        calc(var(--morebtn-y) * 1px),
        0
      )
      scale(0);
    @apply will-change-transform absolute left-0 top-0 w-[123px] h-[36px] btn-unstyled bg-[#ff2800] rounded-[28px] border-[3px] border-black text-white text-[12px] font-semibold transition-all duration-75 ease-linear;
  }

  .group:hover .show.home-more {
    transform: translate3d(
        calc(var(--morebtn-x) * 1px),
        calc(var(--morebtn-y) * 1px),
        0
      )
      scale(1);
  }

  nav.nav-v1 {
    ul {
      display: flex;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.06em;
      color: #ffffff;
      margin: 0;
    }

    ul li {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      list-style: none;
    }

    ul li:not(:last-child) {
      margin-right: 40px;
    }

    .account-ul li:nth-last-child(3) {
      margin-right: 43.75px;
    }

    .account-ul li:nth-last-child(2) {
      margin-right: 33.75px;
    }

    li a {
      color: #fff;
    }
  }

  footer.footer-v1 ul {
    letter-spacing: normal;
    margin: 0;
    padding: 0;
    & > li {
      list-style: none;
      margin: 0;
    }
  }
}

.c_roate {
  transition: transform 0.5s ease-out !important;
}

.c_roate:hover {
  transform: rotateZ(180deg) !important;
}

.variantbg {
  background-color: #1d1d1d !important;
  border-radius: 16px !important;
  min-width: auto !important;
  max-width: 340px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

@import "../components/UI/Dropdown/index.css";
@import "../components/UI/hero-button.css";
@import "../components/UI/counter.css";
