.ui__counter input {
  height: 56px !important;
  box-sizing: border-box;
  text-align: center;
  font-size: 32px !important;
  font-weight: bold;
  -webkit-text-fill-color: #000 !important;
  padding: 0 !important;
  font-family: "DIN_Alternate" !important;
}

.ui__counter--disabled .bnt-op {
  cursor: initial;
}
